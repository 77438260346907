@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto Slab', sans-serif;
}
.text_Deco{
  text-decoration: underline;
  color:#C0252D
}
::selection {
  color:white;
  background: #b42e35;
}
.fn-color{
  color: green;
}
.fn_14{
  font-size: 14px;
}
.fn_12{
  font-size: 12px;
}
.fn_10{
  font-size: 10px;
}
.fn_18{
  font-size: 18px;
}
.fn_24{
  font-size: 24px;
}
.mt15{
  margin-top: -12px;
}

.fb_600{
  font-weight: 600;
}
.fb_700{
  font-weight: 700;
}
.text_grey{
  color: rgb(89, 89, 89);
}

.btn-outline-danger{
  padding: 7px 20px;
  border-radius: 0;
  font-weight: 500;
}
.btn-outline-danger:hover{
  background-color: #C0252D !important;
}

.text-custom{
  color:#C0252D !important;
  
}
.btn-primary {
  background-color: #C0252D !important;
  padding: 7px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
}

.btn-primary:hover {
  background-color: #c70912 !important;
  box-shadow: none !important;
}
.btn-dark{
  background-color: #000000;
  padding: 7px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
}
button{
  box-shadow: none !important;
}

.error {
  width: 100%;
  display: grid;
  place-items: center;
  background-color: ghostwhite;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.error h1 {
  font-size: 60px;
  font-weight: 700;
  text-shadow: 0px 0px 5px rgb(226, 131, 147);
}



.blank_page{
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}






::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(199, 194, 194);
}

.card {
  border: none;
  border-radius: 0;
  box-shadow: 0px 5px 15px gainsboro;
}
.cur {
  cursor: pointer;
}
.mbneg {
  margin-bottom: -7px;
}

.matchcom {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-wrap: wrap;
}
.masmall {
  padding: 2px 7px;
  background: grey;
  color: white;
  border-radius: 15px;
  cursor: pointer;
  font-size: 10px;
  margin: 1px;
}
.box{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-control {
  border:1px solid #C0252D !important;
  
}
.form-control:focus{
  
box-shadow: none;
  
}
.wid{
  max-width: 70%;
  display: block;
  margin: auto;
}

@media (max-width:500px) {
  .wid{
    max-width: 100%;
    
  }
}
@media (min-width:750px ) and (max-width:1000px) {
  .wid{
    max-width: 100%;
    
  }
}

.modbox{
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smbox{
  min-width:400px ;
  height: auto;
  background-color: white;
  padding: 15px;
  position: relative;
}
.smbox .items{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #C0252D;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -10px;
  top: -10px;
  color: white;
  cursor: pointer;
}









.whole__page{
  
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.left_side_navbar{
  position: fixed;
  top: 0;
  left: 0;
   width: 18%;
   height: 100vh;  
   overflow-y: auto;  
   background: white;
   padding-bottom: 50px;
 

}

.side_navbar_inner  a{
   
  display: block;
  color:black;

  padding: 10px ;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  width: 80%;
  
  margin: 5px;
  
}
.side_navbar_inner  a:hover{
  background-color: #C0252D;
  color:white
}
.side_navbar_inner  .active{
  background-color: #C0252D;
  color:white
}
.side_navbar_inner a:hover{
  text-decoration: none;
}

.side_navbar_inner{
    
  margin-top: 100px;
  
}

.main_div{
  position: absolute;
  top:0;
  right: 0;
  width: 82%;
  min-height: 100vh;
  /* font-size: 45px; */
  /* background-color: aquamarine; */
  background-color: ghostwhite;
  
  /* padding: 15px; */
  padding:  100px 0px 50px 0px;
}
.main_inner_div{
  width: 95%;
  display: block;
  margin: auto;
}


.resp_dis{
  display: none !important;
}



@media (max-width:776px) {
  .main_div{
    position: relative;
  }
  .resp_dis{
    display: block !important;
  }
  .left_side_navbar{
    display: none;
}

.main_div{
    width: 100%;
}
  
}


@media (max-width:600px) {
  .smbox{
    width: 80%;
  }
}



.dflex{
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  align-items: center !important;
  
}
.d_flex{
  width: 200px;
  height: 100%;
  display: flex;
  
  align-items: center;
}
.textm{
  margin-top: -15px;
  margin-left: 5px;
  color: #C0252D;
}
.textm:hover{
  color: #C0252D;
}





.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 38px;
  width: 100%;
  padding: 0 5px;
  border: 1px solid #C0252D;
  border-radius: 5px;
  
}
.tags-input:focus-within {
  border: 1px solid #C0252D;
}

.tags-input input {
  flex: 1;
  border: none;
  height: 40px;
  font-size: 14px;
  border-radius: 5px;
  
  padding: 5px 0 0 5px !important;
  /* background-color: transparent !important; */
}
.tags-input input:focus {
  outline: transparent;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 5px;
  font-size: 10px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #f2535b;
}
.tag .tag-title {
  /* margin-top: 3px; */
}
.tag .tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 12px;
  margin-left: 8px;
  color: #c70912;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

.searchinput {
  width: 100%;
  height: 150px;
  background-color: white;
  box-shadow: 0px 2px 5px gainsboro;
  position: absolute;
  z-index: 5;
  padding: 5px;
  overflow: auto;
  border-bottom: 2px solid #0052cc;
}
.tagbox {
  width: 100%;
  height: auto;
  position: relative;
}
.searchinput ul {
  list-style-type: none;
}
.searchinput ul li {
  font-size: 12px;
  border-bottom: 1px solid #1067d8;
  margin: 2px;
  cursor: pointer;
}


.pageloading{
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0;
  left: 0;
  z-index: 9999;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* .w_10{
  width: 100px;
} */

#__react-alert__ div div div {
  width: auto !important;
}


.model_box{
  width: 100%;
  height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(222, 73, 73,0.5);
  z-index: 99999;
}
.model-up{
  /* width: 500px; */
  max-height:500px ;
  padding: 15px;
  overflow: auto;
}
.inner_model{
  width:500px;
 
  background-color: white;
  height: auto;
  padding: 15px;
  position: relative;
}

.inner_model_sm{
  width:350px;
  background-color: white;
  height: auto;
  padding: 15px;
  position: relative;
}
.cross{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: -10px;
  top:-10px;
}

@media (max-width:650px) {
  .inner_model , .inner_model_sm{
      width: 90%;
  }
  .model-up{
    width: 95%;
  }
  
}


.disable-scroll{
  /* width: 82%; */
  height: 100vh;
  overflow-y: hidden;
}
.dis{
  cursor: no-drop !important;
}