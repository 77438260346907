footer{
    width: 100%;
    min-height: 45vh;
    background-color:#C0252D;
    padding: 50px 0;
    color: white;
    display: none;
    /* position: absolute; */
}
footer h5{
    color:white;
    font-size: 16px;
}
footer p{
    font-size: 12px;
}
.p_mar{
    margin-bottom:0 ;
}
footer ul{
    list-style-type: none;
}

footer ul li a {
    color: white ;
    font-size: 12px;
}


footer ul li a:hover {
    color: white ;
    font-size: 12px;
    text-decoration: none;
}

.social_icon_box{
    display: flex;
    
  }
  .social_icon{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid white;
  margin: 2px 4px;
  }
  .fright{
    display: flex;
    justify-content: end;
  }
  .social_icon a{
    color:white;
  }
  .social_icon:hover{
    background-color: white;
    color: darkblue;
    text-decoration: none;

  }
  .social_icon:hover a{
    color:darkblue
  }


  @media (max-width:776px) {
    footer{
      display: block !important;
    }
    
  }