.cat_img{
    width: 100px;
    height: 100px;
    display: block;
    margin: auto;
    object-fit: contain;
}
.delete_box{
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bd2f36;
    color:white;
    font-size: 10px;
}