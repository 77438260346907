@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto Slab', sans-serif;
}
.text_Deco{
  text-decoration: underline;
  color:#C0252D
}
::selection {
  color:white;
  background: #b42e35;
}
.fn-color{
  color: green;
}
.fn_14{
  font-size: 14px;
}
.fn_12{
  font-size: 12px;
}
.fn_10{
  font-size: 10px;
}
.fn_18{
  font-size: 18px;
}
.fn_24{
  font-size: 24px;
}
.mt15{
  margin-top: -12px;
}

.fb_600{
  font-weight: 600;
}
.fb_700{
  font-weight: 700;
}
.text_grey{
  color: rgb(89, 89, 89);
}

.btn-outline-danger{
  padding: 7px 20px;
  border-radius: 0;
  font-weight: 500;
}
.btn-outline-danger:hover{
  background-color: #C0252D !important;
}

.text-custom{
  color:#C0252D !important;
  
}
.btn-primary {
  background-color: #C0252D !important;
  padding: 7px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
}

.btn-primary:hover {
  background-color: #c70912 !important;
  box-shadow: none !important;
}
.btn-dark{
  background-color: #000000;
  padding: 7px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
}
button{
  box-shadow: none !important;
}

.error {
  width: 100%;
  display: grid;
  place-items: center;
  background-color: ghostwhite;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.error h1 {
  font-size: 60px;
  font-weight: 700;
  text-shadow: 0px 0px 5px rgb(226, 131, 147);
}



.blank_page{
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}






::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(199, 194, 194);
}

.card {
  border: none;
  border-radius: 0;
  box-shadow: 0px 5px 15px gainsboro;
}
.cur {
  cursor: pointer;
}
.mbneg {
  margin-bottom: -7px;
}

.matchcom {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-wrap: wrap;
}
.masmall {
  padding: 2px 7px;
  background: grey;
  color: white;
  border-radius: 15px;
  cursor: pointer;
  font-size: 10px;
  margin: 1px;
}
.box{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-control {
  border:1px solid #C0252D !important;
  
}
.form-control:focus{
  
box-shadow: none;
  
}
.wid{
  max-width: 70%;
  display: block;
  margin: auto;
}

@media (max-width:500px) {
  .wid{
    max-width: 100%;
    
  }
}
@media (min-width:750px ) and (max-width:1000px) {
  .wid{
    max-width: 100%;
    
  }
}

.modbox{
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smbox{
  min-width:400px ;
  height: auto;
  background-color: white;
  padding: 15px;
  position: relative;
}
.smbox .items{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #C0252D;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -10px;
  top: -10px;
  color: white;
  cursor: pointer;
}









.whole__page{
  
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.left_side_navbar{
  position: fixed;
  top: 0;
  left: 0;
   width: 18%;
   height: 100vh;  
   overflow-y: auto;  
   background: white;
   padding-bottom: 50px;
 

}

.side_navbar_inner  a{
   
  display: block;
  color:black;

  padding: 10px ;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  width: 80%;
  
  margin: 5px;
  
}
.side_navbar_inner  a:hover{
  background-color: #C0252D;
  color:white
}
.side_navbar_inner  .active{
  background-color: #C0252D;
  color:white
}
.side_navbar_inner a:hover{
  text-decoration: none;
}

.side_navbar_inner{
    
  margin-top: 100px;
  
}

.main_div{
  position: absolute;
  top:0;
  right: 0;
  width: 82%;
  min-height: 100vh;
  /* font-size: 45px; */
  /* background-color: aquamarine; */
  background-color: ghostwhite;
  
  /* padding: 15px; */
  padding:  100px 0px 50px 0px;
}
.main_inner_div{
  width: 95%;
  display: block;
  margin: auto;
}


.resp_dis{
  display: none !important;
}



@media (max-width:776px) {
  .main_div{
    position: relative;
  }
  .resp_dis{
    display: block !important;
  }
  .left_side_navbar{
    display: none;
}

.main_div{
    width: 100%;
}
  
}


@media (max-width:600px) {
  .smbox{
    width: 80%;
  }
}



.dflex{
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  align-items: center !important;
  
}
.d_flex{
  width: 200px;
  height: 100%;
  display: flex;
  
  align-items: center;
}
.textm{
  margin-top: -15px;
  margin-left: 5px;
  color: #C0252D;
}
.textm:hover{
  color: #C0252D;
}





.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 38px;
  width: 100%;
  padding: 0 5px;
  border: 1px solid #C0252D;
  border-radius: 5px;
  
}
.tags-input:focus-within {
  border: 1px solid #C0252D;
}

.tags-input input {
  flex: 1 1;
  border: none;
  height: 40px;
  font-size: 14px;
  border-radius: 5px;
  
  padding: 5px 0 0 5px !important;
  /* background-color: transparent !important; */
}
.tags-input input:focus {
  outline: transparent;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 5px;
  font-size: 10px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #f2535b;
}
.tag .tag-title {
  /* margin-top: 3px; */
}
.tag .tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 12px;
  margin-left: 8px;
  color: #c70912;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

.searchinput {
  width: 100%;
  height: 150px;
  background-color: white;
  box-shadow: 0px 2px 5px gainsboro;
  position: absolute;
  z-index: 5;
  padding: 5px;
  overflow: auto;
  border-bottom: 2px solid #0052cc;
}
.tagbox {
  width: 100%;
  height: auto;
  position: relative;
}
.searchinput ul {
  list-style-type: none;
}
.searchinput ul li {
  font-size: 12px;
  border-bottom: 1px solid #1067d8;
  margin: 2px;
  cursor: pointer;
}


.pageloading{
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0;
  left: 0;
  z-index: 9999;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
  -webkit-animation: lds-spinner 1.2s linear infinite;
          animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* .w_10{
  width: 100px;
} */

#__react-alert__ div div div {
  width: auto !important;
}


.model_box{
  width: 100%;
  height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(222, 73, 73,0.5);
  z-index: 99999;
}
.model-up{
  /* width: 500px; */
  max-height:500px ;
  padding: 15px;
  overflow: auto;
}
.inner_model{
  width:500px;
 
  background-color: white;
  height: auto;
  padding: 15px;
  position: relative;
}

.inner_model_sm{
  width:350px;
  background-color: white;
  height: auto;
  padding: 15px;
  position: relative;
}
.cross{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: -10px;
  top:-10px;
}

@media (max-width:650px) {
  .inner_model , .inner_model_sm{
      width: 90%;
  }
  .model-up{
    width: 95%;
  }
  
}


.disable-scroll{
  /* width: 82%; */
  height: 100vh;
  overflow-y: hidden;
}
.dis{
  cursor: no-drop !important;
}
.whole_nav{
  width: 100%;
  height: 60px;
 
  background-color: #FFFFFF;
  box-shadow: 0px 1px 5px grey;
  position:fixed;
  z-index: 99;
}


.nav-item a{
    color:white !important;
}
.dot{
 
   background: white;
   padding: 5px;
   
}
.logo_text{
  color:green;
  margin: 0;
  padding: 0;

}
.dflex{
    display: flex;
    justify-content: center;
    align-items: center;
}

nav{
    display: flex;
    justify-content:space-between !important;
    align-items: center;
    width: 100%;
    height: 100%;
    
}
.navitem{
    color: black;
    margin-right: 17px;
}
.navitem:hover{
    color: black;
    text-decoration: none;
}
.logoimg{
    width: 100%;
    height: 45px;
    object-fit: contain;
}
.bglight{
    background-color: white;
    /* box-shadow: 0px 1px 1px rgb(242, 237, 237) !important; */
}
hr{
  background-color: white;
}

.listsize {
    width: 250px;
  }
  .nav-wid {
    background-color: #C0252D !important;
    
  }
  .nav-wid a{
    color:white;
    font-size: 16px !important;
  }
  .nav-wid a:hover{
    color:white;
    text-decoration: none;
  }
  .MuiMenu-list {
    width: 250px !important;
    /* height: 250px !important; */
    overflow: auto;
  }
  /* .MuiMenu-paper {
    top: 50px !important;
  } */
  
  .MuiMenuItem-root {
    margin-bottom: 3px !important;
    /* border-bottom: 1px solid rgba(42, 159, 34, 0.5) !important; */
    /* background-color: rgb(14, 71, 52) !important; */
   

  }
  .MuiMenuItem-root a{
    display: flex;
    align-items: center;
    color:#000000;
  }
  .MuiMenuItem-root a:hover{
    color: #000000;
    text-decoration: none;
  }
.MuiButtonBase-root:hover a{
  
  color: white !important;
}
  .MuiList-root a:hover{
    background-color: #C0252D;
    color: white !important;
    
  }
  .right_nav_link li:hover {
    background-color: #C0252D;
    color: white !important;
    
  }
  .right_nav_link li:hover .MuiListItemIcon-root{
    
    color: white !important;
  }
  .searchdiv{
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto ;
    padding: 2px;
}
.input-search{
    width: 100%;
    height: 80%;

    padding:0px 15px;
   
    border: 1px solid #000000 !important;
    
}

.form-control:focus{
  
    box-shadow: none;
      
    }

    
  
  .searchdiv .input-group  {
      width: 90%;
      
      border-radius: 15px;
      background: rgba(246, 241, 241,0.5);
      margin: auto;
      padding: 3px ;
  }
  
  
  .searchdiv .form-control {
      border:0 !important;
      border-radius: 15px !important;
      box-shadow: none !important;
      margin: 2px;
      background:transparent !important;
  }
  .searchdiv .input-group-text  {
  width: auto;
  background: green;
  border: 0 !important;
  color: #fff;
  border-radius: 15px !important;
      box-shadow: none !important;
      padding: 0 15px !important;
  }
  

  .modbox{
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .smbox{
    min-width:300px ;
    height: auto;
    background-color: white;
    /* padding: 15px; */
    position: relative;
  }

  .sm_box{
    width:400px ;
    height: auto;
    background-color: white;
    /* padding: 15px; */
    position: relative;
  }
  .bigbox{
    width:600px ;
    height: auto;
    background-color: white;
    /* padding: 15px; */
    position: relative;
    padding: 15px;
  }
  .btn_close{
    width: 25px;
    height: 25px;
    background-color: #C0252D;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top:-10px;
    right: -10px;
    border-radius: 50%;
    color:white;
    cursor: pointer;
  }


  @media (max-width:450px) {
    .sm_box{
      width:90% ;
      
    }
  }
  @media (max-width:700px) {
    .bigbox{
      width:90% ;
      
    }
  }

  
.auth {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ghostwhite;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  overflow: hidden;
}
.auth::before {
  position: absolute;
  content: '';
  top: 0px;
  left: 0;

  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url('https://raw.githubusercontent.com/atanu20/front-end-webdev/master/image/left.svg');

  background-repeat: no-repeat;
  background-position: left top;
  background-size: 25%;
  -webkit-animation: updown 6s linear infinite alternate;
          animation: updown 6s linear infinite alternate;
}
@-webkit-keyframes updown {
  0%   {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  50%  {-webkit-transform: translateY(0%);transform: translateY(0%);}
  
  100% {-webkit-transform: translateY(5%);transform: translateY(5%);}
}
@keyframes updown {
  0%   {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  50%  {-webkit-transform: translateY(0%);transform: translateY(0%);}
  
  100% {-webkit-transform: translateY(5%);transform: translateY(5%);}
}

.auth::after {
  position: absolute;
  content: '';
  top: 0px;
  right: 0;

  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url('https://raw.githubusercontent.com/atanu20/front-end-webdev/master/image/right.svg');

  background-repeat: no-repeat;
  background-position: right top;
  background-size: 25%;
  -webkit-animation: updown 5s linear infinite alternate;
          animation: updown 5s linear infinite alternate;
}

.dis {
  cursor: no-drop !important;
}
.padd {
  padding: 50px 0;
}

@media (max-width: 600px) {
  .auth::before {
    background-size: 50%;
  }
}

.userimg{
  width: 100px;
  height: 100px;
border-radius: 50%;
}


.passform{
  position: relative;
  
}
.passform div{
  position: absolute;
  right: 7px;
  top: 6.5px;
  background-color: white;

  
}
footer{
    width: 100%;
    min-height: 45vh;
    background-color:#C0252D;
    padding: 50px 0;
    color: white;
    display: none;
    /* position: absolute; */
}
footer h5{
    color:white;
    font-size: 16px;
}
footer p{
    font-size: 12px;
}
.p_mar{
    margin-bottom:0 ;
}
footer ul{
    list-style-type: none;
}

footer ul li a {
    color: white ;
    font-size: 12px;
}


footer ul li a:hover {
    color: white ;
    font-size: 12px;
    text-decoration: none;
}

.social_icon_box{
    display: flex;
    
  }
  .social_icon{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid white;
  margin: 2px 4px;
  }
  .fright{
    display: flex;
    justify-content: end;
  }
  .social_icon a{
    color:white;
  }
  .social_icon:hover{
    background-color: white;
    color: darkblue;
    text-decoration: none;

  }
  .social_icon:hover a{
    color:darkblue
  }


  @media (max-width:776px) {
    footer{
      display: block !important;
    }
    
  }
.form_h{
    height: 40px;
}
.red_card{
    width: 100%;
    height: 200px;
    background-color: #bd2f36;
    border-radius: 10px;
    flex: 1 1;
    padding: 15px;
    position: relative;
}
.media{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    
    flex-direction: column;
    color: white;
}

.btn-outline-dark{
    border: 1px solid white;
    color: white;
}
.btn-outline-dark:hover{
    background-color: white;
    color: #C0252D;
    border: none;
}
.cartoon_img{
    position: absolute;
    right: 0;
    bottom:  0;
}
.cartoon_img img{
    width: 100%;
    height: 150px;
}

 h4{
    font-size: 16px;
}

.dash h1{
    font-size: 24px;
}

.dash .card{
    border: 5px solid white;
    border-bottom: 5px solid #C0252D;
    transition: all .5s ease-in-out;
}
.dash .card:hover{
    /* background-color: #C0252D; */
    /* color: white; */
    border: 5px solid #C0252D;
    
}
table th{
    border-top: none !important;
    border-bottom: 2px solid #bd2f36 !important;
}
 table td{
border: none !important;
}
.dd_flex{
    display: flex;
    justify-content: space-between;
}
.mt0{
    margin-top: -17px;
    margin-left: 2px;
}
.dnd_flex{
    display: flex;
    justify-content: center;
    align-items: center;
}

.filterform{
    width: 120px;
    height: 35px !important;
font-size: 15px;
}
.tr_w{
    width: 250px;
   
}
.tr_ww{
    width: 400px;
}
.small_det{
    width: 100%;
    height: 100%;
    /* background-color: aliceblue; */
    display: flex;
    /* justify-content:center; */
    align-items: center;
   
}
.small_det div p{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
    margin-left: 5px;
}
.small_det img{
    width: 50px;
    height: 50px;
    background-color: rgba(245, 196, 175,0.7);
    padding: 3px;
    border-radius: 5px;
    object-fit: contain;
}
.badge{
padding: 5px;
}
.badge-danger{
    background-color: rgba(255,0,0,.2);
    color: red;
    
  }
  
  .badge-warning{
    background-color: rgba(255,165,0,.2);
    color: orange;
  
  }
  
  .badge-success{
    background-color: rgba(43,210,43,.3);
      color: green;
    
  }

.listmy table tbody {
    display: inline-block;
   
    max-height: 300px;
    overflow-y: auto;
  }
  
 .listmy table thead, .listmy table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
 
  .pop_img{
    width: 100%;
    height: 250px;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }



.requ  p{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}



.pagination {
    display: flex;
    justify-content: end;
    width: 90%;
    /* padding-top: 50px; */
    color: #000000;
    /* margin: auto; */
  }
  .pagination a {
    padding: 5px 8px;
    margin: 5px;
    border: 2px solid #C0252D;
    color: black !important;
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px !important;
  }
  .pagination a:hover {
    color: #fff !important;
    text-decoration: none;
    background-color: #C0252D;
  }
  .activebutton a {
    background-color: #C0252D;
    color: #fff !important;
  }

  .ck-editor{
    border: 1px solid #C0252D !important;
  }
  .ck_editor__top{
    border: 1px solid #C0252D !important;
  }
.ck-content{
    min-height: 150px !important;
    border-top: 1px solid #C0252D !important;
}

.ck-content:focus{
 
    box-shadow: none !important;
    border: none !important;
    border-top: 1px solid #C0252D !important;
}

.newdd_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width:700px) {
    .cartoon_img img{
        width: 100%;
        height: 130px;
    }
    .card canvas{
        height: 300px !important;
      }
      .newdd_flex{
        display: flex;
    justify-content: center;
    align-items: center;
        flex-direction: column !important;
      }
}



.sell_box{
    position: relative;
}
.status_card{
    position: absolute;
    left: 0;
    top: 0;
    
    padding: 5px;
   
    background-color: white;
    box-shadow: 0px 2px 4px grey;
    display: flex;
    justify-content: center;
    align-items: center;
}
.right_card{
    position: absolute;
    right: 1px;
    top: 1px;
    cursor: pointer;
    padding: 5px;
    background-color: white;
    box-shadow: 0px 2px 4px grey;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sell_box img{
    width: 80%;
    height: 150px;
    display: block;
    margin: auto;
    object-fit: contain;
}
.sell_box h4{
    font-size: 20px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
 .ptext{
    font-size: 14px;
    margin: 0;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

table{
    table-layout:fixed;
  }

.w_20{
    width: 200px;
  }
  .w_10{
    width: 100px;
  }
  .w_d{
    width: 120px;
  }
  .w_30{
    width: 250px;
  }

  .img_flex{
    display: grid;
    
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    grid-gap: 5px;
    gap: 5px;
}
.img_flex_item{
    height: 140px;
    width: 100%;
    border: 1px solid brown;
    box-shadow: 0px 2px 4px grey;
    cursor: pointer;
    border-radius: 5px;
}
.img_flex_item img{
    width: 100%;
    height: 100%;
    border-radius: 5px;

}

@media (min-width: 767px){
    .modal-lg {
        max-width: 80%;
    }
    }


    @media (max-width:500px) {
        .cartoon_img {
            display: none;
        }
        .media{
            align-items: center;
        }
        .tr_w{
            width: 200px;
        }
       
        
        .w_20{
            width: 150px;
        } 
        .small_det img{
           display: none;
        }
        
    }
    

  .productflex{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 30px;
    gap: 30px;
}
.diplay_flex{
display: flex;
align-items: center;
}
.product_item{
    width: 100%;
    height: auto;
    border: 1px solid brown;
    padding: 10px;
    border-radius: 5px;
    position: relative;
}
.product_item a{
    color: #414141;
    text-align: center;
    
}
.product_item a:hover{
    color: black;
    text-decoration: none;
}
.product_item img{
    width: 120px;
    height: 120px;
    object-fit: contain;
}
.sm_p{
    margin: 0;
display: -webkit-box;
overflow: hidden;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}

@media (max-width:600px) {
    .diplay_flex{
        flex-direction: column;
    }
}

.doc_fileimg{
    width: 100%;
    height: 400px;
    object-fit: contain;
    margin: 15px 0;
}

.form-group .App{
    max-width: 100% !important;
}
.jodit-container{
    width: 100% !important;
    border: 1px solid #bd2f36 !important;
}
.jodit-toolbar__box{
    border-bottom: 1px solid #bd2f36 !important;
}
.jodit-status-bar{
    display: none !important;
}
.w_com{
    width: 400px;
}
.qutext{
    margin: 0;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.cat_img{
    width: 100px;
    height: 100px;
    display: block;
    margin: auto;
    object-fit: contain;
}
.delete_box{
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bd2f36;
    color:white;
    font-size: 10px;
}
.new_dd_flex{
    width: 100%;
    display: flex;
    justify-content:space-between;
align-items: center;
}

.banner_flex{
    display: grid;
    
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    grid-gap: 20px;
    gap: 20px;
  }
  .ads_flex{
    display: grid;
    
    grid-template-columns: repeat(auto-fill, minmax(95%, 1fr));
    grid-gap: 20px;
    gap: 20px;
  }
  .banner_flex_item{
    height: 300px;
    width: 100%;
    
    box-shadow: 0px 2px 4px grey;
    
    border-radius: 5px;
    padding: 10px;
    position: relative;
  }
  .s_ad_img{
    height: 500px;
  }
  .banner_flex_item img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: block;
    margin: auto;
    object-fit: contain;
  
  }
  .delete_btn{
    position: absolute;
    right: -10px;
    top: -10px;
    background-color:#bd2f36;
    cursor: pointer;
    color:white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

@media (max-width:400px) {
    .new_dd_flex{
        flex-direction: column;
    }
}

@media (max-width:600px) {
    .banner_flex{
        grid-template-columns: repeat(auto-fill, minmax(90%, 1fr));
    }
}
