.form_h{
    height: 40px;
}
.red_card{
    width: 100%;
    height: 200px;
    background-color: #bd2f36;
    border-radius: 10px;
    flex: 1 ;
    padding: 15px;
    position: relative;
}
.media{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    
    flex-direction: column;
    color: white;
}

.btn-outline-dark{
    border: 1px solid white;
    color: white;
}
.btn-outline-dark:hover{
    background-color: white;
    color: #C0252D;
    border: none;
}
.cartoon_img{
    position: absolute;
    right: 0;
    bottom:  0;
}
.cartoon_img img{
    width: 100%;
    height: 150px;
}

 h4{
    font-size: 16px;
}

.dash h1{
    font-size: 24px;
}

.dash .card{
    border: 5px solid white;
    border-bottom: 5px solid #C0252D;
    transition: all .5s ease-in-out;
}
.dash .card:hover{
    /* background-color: #C0252D; */
    /* color: white; */
    border: 5px solid #C0252D;
    
}
table th{
    border-top: none !important;
    border-bottom: 2px solid #bd2f36 !important;
}
 table td{
border: none !important;
}
.dd_flex{
    display: flex;
    justify-content: space-between;
}
.mt0{
    margin-top: -17px;
    margin-left: 2px;
}
.dnd_flex{
    display: flex;
    justify-content: center;
    align-items: center;
}

.filterform{
    width: 120px;
    height: 35px !important;
font-size: 15px;
}
.tr_w{
    width: 250px;
   
}
.tr_ww{
    width: 400px;
}
.small_det{
    width: 100%;
    height: 100%;
    /* background-color: aliceblue; */
    display: flex;
    /* justify-content:center; */
    align-items: center;
   
}
.small_det div p{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
    margin-left: 5px;
}
.small_det img{
    width: 50px;
    height: 50px;
    background-color: rgba(245, 196, 175,0.7);
    padding: 3px;
    border-radius: 5px;
    object-fit: contain;
}
.badge{
padding: 5px;
}
.badge-danger{
    background-color: rgba(255,0,0,.2);
    color: red;
    
  }
  
  .badge-warning{
    background-color: rgba(255,165,0,.2);
    color: orange;
  
  }
  
  .badge-success{
    background-color: rgba(43,210,43,.3);
      color: green;
    
  }

.listmy table tbody {
    display: inline-block;
   
    max-height: 300px;
    overflow-y: auto;
  }
  
 .listmy table thead, .listmy table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
 
  .pop_img{
    width: 100%;
    height: 250px;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }



.requ  p{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}



.pagination {
    display: flex;
    justify-content: end;
    width: 90%;
    /* padding-top: 50px; */
    color: #000000;
    /* margin: auto; */
  }
  .pagination a {
    padding: 5px 8px;
    margin: 5px;
    border: 2px solid #C0252D;
    color: black !important;
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px !important;
  }
  .pagination a:hover {
    color: #fff !important;
    text-decoration: none;
    background-color: #C0252D;
  }
  .activebutton a {
    background-color: #C0252D;
    color: #fff !important;
  }

  .ck-editor{
    border: 1px solid #C0252D !important;
  }
  .ck_editor__top{
    border: 1px solid #C0252D !important;
  }
.ck-content{
    min-height: 150px !important;
    border-top: 1px solid #C0252D !important;
}

.ck-content:focus{
 
    box-shadow: none !important;
    border: none !important;
    border-top: 1px solid #C0252D !important;
}

.newdd_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width:700px) {
    .cartoon_img img{
        width: 100%;
        height: 130px;
    }
    .card canvas{
        height: 300px !important;
      }
      .newdd_flex{
        display: flex;
    justify-content: center;
    align-items: center;
        flex-direction: column !important;
      }
}



.sell_box{
    position: relative;
}
.status_card{
    position: absolute;
    left: 0;
    top: 0;
    
    padding: 5px;
   
    background-color: white;
    box-shadow: 0px 2px 4px grey;
    display: flex;
    justify-content: center;
    align-items: center;
}
.right_card{
    position: absolute;
    right: 1px;
    top: 1px;
    cursor: pointer;
    padding: 5px;
    background-color: white;
    box-shadow: 0px 2px 4px grey;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sell_box img{
    width: 80%;
    height: 150px;
    display: block;
    margin: auto;
    object-fit: contain;
}
.sell_box h4{
    font-size: 20px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
 .ptext{
    font-size: 14px;
    margin: 0;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

table{
    table-layout:fixed;
  }

.w_20{
    width: 200px;
  }
  .w_10{
    width: 100px;
  }
  .w_d{
    width: 120px;
  }
  .w_30{
    width: 250px;
  }

  .img_flex{
    display: grid;
    
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 5px;
}
.img_flex_item{
    height: 140px;
    width: 100%;
    border: 1px solid brown;
    box-shadow: 0px 2px 4px grey;
    cursor: pointer;
    border-radius: 5px;
}
.img_flex_item img{
    width: 100%;
    height: 100%;
    border-radius: 5px;

}

@media (min-width: 767px){
    .modal-lg {
        max-width: 80%;
    }
    }


    @media (max-width:500px) {
        .cartoon_img {
            display: none;
        }
        .media{
            align-items: center;
        }
        .tr_w{
            width: 200px;
        }
       
        
        .w_20{
            width: 150px;
        } 
        .small_det img{
           display: none;
        }
        
    }
    

  .productflex{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
}
.diplay_flex{
display: flex;
align-items: center;
}
.product_item{
    width: 100%;
    height: auto;
    border: 1px solid brown;
    padding: 10px;
    border-radius: 5px;
    position: relative;
}
.product_item a{
    color: #414141;
    text-align: center;
    
}
.product_item a:hover{
    color: black;
    text-decoration: none;
}
.product_item img{
    width: 120px;
    height: 120px;
    object-fit: contain;
}
.sm_p{
    margin: 0;
display: -webkit-box;
overflow: hidden;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}

@media (max-width:600px) {
    .diplay_flex{
        flex-direction: column;
    }
}

.doc_fileimg{
    width: 100%;
    height: 400px;
    object-fit: contain;
    margin: 15px 0;
}