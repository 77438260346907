.auth {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ghostwhite;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  overflow: hidden;
}
.auth::before {
  position: absolute;
  content: '';
  top: 0px;
  left: 0;

  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url('https://raw.githubusercontent.com/atanu20/front-end-webdev/master/image/left.svg');

  background-repeat: no-repeat;
  background-position: left top;
  background-size: 25%;
  animation: updown 6s linear infinite alternate;
}
@keyframes updown {
  0%   {
    transform: translateY(-5%);
  }
  50%  {transform: translateY(0%);}
  
  100% {transform: translateY(5%);}
}

.auth::after {
  position: absolute;
  content: '';
  top: 0px;
  right: 0;

  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url('https://raw.githubusercontent.com/atanu20/front-end-webdev/master/image/right.svg');

  background-repeat: no-repeat;
  background-position: right top;
  background-size: 25%;
  animation: updown 5s linear infinite alternate;
}

.dis {
  cursor: no-drop !important;
}
.padd {
  padding: 50px 0;
}

@media (max-width: 600px) {
  .auth::before {
    background-size: 50%;
  }
}

.userimg{
  width: 100px;
  height: 100px;
border-radius: 50%;
}


.passform{
  position: relative;
  
}
.passform div{
  position: absolute;
  right: 7px;
  top: 6.5px;
  background-color: white;

  
}