.form-group .App{
    max-width: 100% !important;
}
.jodit-container{
    width: 100% !important;
    border: 1px solid #bd2f36 !important;
}
.jodit-toolbar__box{
    border-bottom: 1px solid #bd2f36 !important;
}
.jodit-status-bar{
    display: none !important;
}
.w_com{
    width: 400px;
}
.qutext{
    margin: 0;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}