.new_dd_flex{
    width: 100%;
    display: flex;
    justify-content:space-between;
align-items: center;
}

.banner_flex{
    display: grid;
    
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    gap: 20px;
  }
  .ads_flex{
    display: grid;
    
    grid-template-columns: repeat(auto-fill, minmax(95%, 1fr));
    gap: 20px;
  }
  .banner_flex_item{
    height: 300px;
    width: 100%;
    
    box-shadow: 0px 2px 4px grey;
    
    border-radius: 5px;
    padding: 10px;
    position: relative;
  }
  .s_ad_img{
    height: 500px;
  }
  .banner_flex_item img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: block;
    margin: auto;
    object-fit: contain;
  
  }
  .delete_btn{
    position: absolute;
    right: -10px;
    top: -10px;
    background-color:#bd2f36;
    cursor: pointer;
    color:white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

@media (max-width:400px) {
    .new_dd_flex{
        flex-direction: column;
    }
}

@media (max-width:600px) {
    .banner_flex{
        grid-template-columns: repeat(auto-fill, minmax(90%, 1fr));
    }
}